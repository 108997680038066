import type { ReactNode } from 'react'

import TEST_IDS from '../../constants/testIds'
import type { CreateComponentsConfig } from '../../types'

import styles from '../GenericStyles.module.scss'

interface ItalicProps {
	readonly children: ReactNode
	readonly customProps?: CreateComponentsConfig['italicProps']
}

/** Renders a italic element with literal text content */
const Italic = ({ children, customProps }: ItalicProps) => {
	const extraProps = { className: styles.italic, ...customProps }
	return (
		<span data-testid={TEST_IDS.ITALIC} {...extraProps}>
			{children}
		</span>
	)
}

export default Italic
