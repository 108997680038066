import type { ReactNode } from 'react'

import TEST_IDS from '../../constants/testIds'
import type { CreateComponentsConfig } from '../../types'

interface TitleProps {
	readonly children: ReactNode
	readonly customProps?: CreateComponentsConfig['titleProps']
}

/** Renders a bold element with literal text content */
const Title = ({ children, customProps }: TitleProps) => {
	const extraProps = { ...customProps }

	return (
		<h2 data-testid={TEST_IDS.TITLE} {...extraProps}>
			{children}
		</h2>
	)
}

export default Title
