import NextImage from 'next/image'

import TEST_IDS from '../../constants/testIds'
import type { CreateComponentsConfig } from '../../types'

interface ImageProps {
	readonly src: string
	readonly alt: string
	readonly customProps?: CreateComponentsConfig['imageProps']
}

export const Image = ({ src, alt, customProps }: ImageProps) => {
	return (
		<NextImage
			data-testid={TEST_IDS.IMAGE}
			alt={alt}
			src={src}
			width={24}
			height={24}
			{...customProps}
		/>
	)
}
